import './Downloads.css';
import React from 'react';
import i18n from '../i18n';
import request from "../request";
import storage from '../storage';
import { faFile, faFileAlt, faDownload, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveAs } from 'file-saver';
import Meta from '../meta';

class Downloads extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      products: [],
      search_text: ''
    };
  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange();
  }


  componentDidMount(){
    document.title = i18n.t('downloads_page_title');
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
      
    request.get('api/product_downloads').then(response => {
        this.setState({
            ...this.state,
            products: response.data
        })
    })
  }

  componentDidUpdate() {
    document.title = i18n.t('downloads_page_title');
  }
    
  getZipProduct(p) {
    request.get(`api/get_zip_product/${p.id}`).then(response => {
        if (response.data && response.data.base64) {
          const data = Buffer.from(response.data.base64, 'base64') || atob(response.data.base64)
          const blob = new Blob([data], {
              type: 'application/octet-stream'
          })
          const filename = `${p.code}.zip`
          saveAs(blob, filename)
        } else {
          alert(i18n.t('login_to_download_msg'))
        }
        
    }) 
  }

  render() {
    return (
      <div className="downloads-page">
        <Meta 
            title={i18n.t('downloads_page_title')}
            description={i18n.t('downloads_page_title')}
            image='https://cotton-plus.eu/img/logo/logo.png'
            url={window.location}
        />
        <div className="search-area">
          <div className='search-item'>
            <input type="text" className='search-products' value={this.state.search_text} onChange={(e) => {
              this.setState({
                ...this.state,
                search_text: e.target.value
              })
            }} />
            <FontAwesomeIcon icon={faSearch} />
          </div>
          
        </div>
            {
                this.state.products && this.state.products.length > 0 &&
                this.state.products.filter(p => this.state.search_text === '' || p.code.includes(this.state.search_text)).map(p => {
                  
                    return (
                        <div className='product-item'>
                            
                            <div>
                            <FontAwesomeIcon className="file-icon" icon={faFileAlt} />
                            <span className='product-code'>#{p.code}</span>
                            <span className='product-date'>{p.timestamp}</span>
                            </div>
                            <div className='download-section' onClick={() => this.getZipProduct(p)}>
                                <FontAwesomeIcon className="file-download" icon={faDownload} />
                              <span>{i18n.t("download")}</span>
                            </div>
                        </div>
                    )
                })
            }
      </div>
    );
  }
}

export default Downloads;