import React from 'react';
import MetaTags from 'react-meta-tags';



class Meta extends React.Component {
    constructor(props) {
        super(props);
		// https://cotton-plus.eu/img/logo/logo.png
        this.state = {
            title: props.title,
			description: props.description,
			image: props.image,
			url: props.url 
        };

    }
    

    
    render() {
        return (
                <MetaTags>
                    <title>{this.state.title}</title>
                    <meta id="meta-description" name="description" content={this.state.description} />
                    <meta property="og:url" content={this.state.url} />
                    <meta property="og:title" content={this.state.title} />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content={this.state.image} />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:site" content="@" />
                    <meta name="twitter:creator" content="@" />
                    <meta name="twitter:title" content={this.state.title} />
                    <meta name="twitter:description" content={this.state.description} />
                    <meta name="twitter:image" content={this.state.image} />
                </MetaTags>
		)
    }
}
export default Meta;