import './HomeServices.css';
import React from 'react';
import {
  Link,
} from "react-router-dom";

import i18n from '../i18n';
import config from '../config';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object
  .keys(Icons)
  .filter(key => key !== "fas" && key !== "prefix" )
  .map(icon => Icons[icon])

library.add(...iconList)

class HomeServices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        elements: props.services
    };
  }

  componentDidMount() {
    
  }

  

  componentDidUpdate() {
  }

  componentWillUnmount() {
  }

  render() {
        return (
            <div className='services-bg'>
                <h3 className="title">WHY Cotton Plus Cyprus?</h3>
                <div className='services-wrapper'>
                    {
                        this.state.elements.map((e, i) => {
                            return <div key={`hs-${i}`} className='services-item'>
                                <a href='#'></a>
                                <div className='icon-bg'>
                                    <FontAwesomeIcon icon={e.icon}  />
                                </div>
                                <FontAwesomeIcon className='main-icon' icon={e.icon}  />
                                <h4>{i18n.get_title(e)}</h4>
                                <div className='services-details'>
                                    <p>{i18n.get_desc(e)}</p>
                                </div>
                                <div className='services-more'>
                                    <Link style={{textDecoration: 'none', color: "#333"}} to={`/about-us/${e.url}`}>{i18n.t("more")} <FontAwesomeIcon icon={`arrow-right`}/></Link>
                                </div>
        
                            </div>
                        })
                    }
                </div>
            </div>
        );
    }
}

export default HomeServices;