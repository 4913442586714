import './Article.css';
import React from 'react';
import i18n from '../i18n';
import request from '../request';
import history from '../history';
import { useParams } from "react-router-dom";
import Meta from '../meta';


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Article extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            articles: [],
            id: this.props.params.id
        };

        this.getArticles = this.getArticles.bind(this);
    }
    
    onLanguageChange = () => {
        this.forceUpdate();
    }

    componentDidMount() {
        document.title = i18n.t("article_page_title");
        this.onHistoryChangeUnlisten = history.listen(() => {
            this.setState({
                ...this.state,
                id: this.props.params.id
            }, () => {
                this.getArticles()
            });
        });
        this.getArticles()
        this.unlistenOnLanguageChange = i18n.onLanguageChange(this.onLanguageChange);
    }

    componentWillUnmount() {
        this.unlistenOnLanguageChange();
    }
    
    getArticles() {
        request.get(`api/article/${this.props.params.id}`).then((response) => {
            this.setState({
                articles: response.data
            }, () => {
                if(this.state.articles && this.state.articles.length > 0)
                    document.title = i18n.toggle(this.state.articles[0].title, this.state.articles[0].title_en);
            });
            
        });
    }
    
    render() {
        return (
            <div className='column container bg-white'>
                {
                    this.state.articles && this.state.articles.length > 0 && 
                    <Meta 
                        title={i18n.toggle(this.state.articles[0].title, this.state.articles[0].title_en)}
                        description={i18n.toggle(this.state.articles[0].title, this.state.articles[0].title_en)}
                        image='https://cotton-plus.eu/img/logo/logo.png'
                        url={window.location}
                    />
                }
                
                {/* <h3 className='title top-title'>ΟΡΟΙ ΚΑΙ ΠΡΟΥΠΟΘΕΣΕΙΣ ΧΡΗΣΗΣ</h3> */}
                <div id="terms-wrapper" style={{minHeight: "60vh"}}>
                    {this.state.articles.map( (article) => {
                        return(
                            <div className='content'>
                                <div class='article-img-wrapper'>
                                    {
                                        article.thumbnail && article.thumbnail !== '' &&
                                        <img className="article-img" src={article.thumbnail}/>
                                    }
                                </div>
                                <h3 className='title text-center'>{i18n.toggle(article.title, article.title_en)}</h3>
                                <div className='term-content'>
                                    <p id="terms-wrapper" dangerouslySetInnerHTML={{__html: i18n.toggle(article.content, article.content_en)}}>
                                        
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                </div>
                
            </div>
        );
    }
}
export default withParams(Article);