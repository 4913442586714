import './ProductGallery.css';
import React from 'react';
import i18n from '../i18n';
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft, faPlay, faPause, faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


class ProductGallery extends React.Component {
  constructor(props) {
    super(props);
    
    this.mainSliderRef = React.createRef(null)
    this.navSliderRef = React.createRef(null)
  }

  componentDidMount() {
    
    i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
  }

  componentDidUpdate(prevProps, prevState) {

  }

  componentWillUnmount() {
    
  }

  

  render() {
    
    return (
      
      this.props.data ?
        <React.Fragment>
          <Slider 
          ref={slider => this.mainSliderRef = slider}
            {...{
            arrows: true,
            autoplay: false,
            adaptiveHeight: false,
            nextArrow: <FontAwesomeIcon icon={faAngleRight}/>,
            prevArrow: <FontAwesomeIcon icon={faAngleLeft}/>,
            asNavFor: this.navSliderRef
          }}>
            {
              this.props.data.map(image => 
                <div className='product-gallery-item'>
                  <img src={image.url} />  
                </div>
              )
            }
                
          </Slider>
          <Slider 
            ref={slider => this.navSliderRef = slider}
            {...{
              infinite: false,
            arrows: false,
            autoplay: false,
            adaptiveHeight: false,
            slidesToShow:5,
            // swipeToSlide: true,
              slidesToScroll: 1,
              variableWidth: true,
              asNavFor: this.mainSliderRef,
              focusOnSelect: true
          }}>
            {
              this.props.data.map(image => 
                <div className='product-gallery-item-preview'>
                  <img src={image.url} />  
                </div>
              )
            }
                
          </Slider>
        </React.Fragment>
        // <div className="ProductGallery">
        //     <div>
        //       <img src={this.state.current_pos !== false && this.state.images[this.state.current_pos].url} />
        //     </div>
        //     <div className="i360-controls" style={{textAlign: "center"}}>
        //       <button onClick={ () => this.leftClick()}>
        //       <FontAwesomeIcon icon={faArrowLeft} />
        //       </button>
        //       <button onClick={ () => this.pauseClick()}>
        //       <FontAwesomeIcon icon={this.state.paused ? faPlay : faPause} />
        //       </button>
        //       <button onClick={ () => this.rightClick()}>
        //       <FontAwesomeIcon icon={faArrowRight} />
        //       </button>
        //     </div>
        // </div>
         :
      ''
    );
  }
}

export default ProductGallery;