import './ProductItem.css';
import React from 'react';
import i18n from '../i18n';
import {
  Link,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart as faHeartFilled, faEye, faShoppingBasket, faTimes, faStar as faStarFilled, faPaperPlane, faGift, faGifts } from '@fortawesome/free-solid-svg-icons'
import { faHeart, faStar } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import NumberPicker from '../number-picker/NumberPicker';
import basket from '../basket';
import favouritesHelper from '../favouritesHelper';
import ProductAvailability from '../product-availability/ProductAvailability';
import ProductAttributes from '../product-attributes/ProductAttributes';
import ProductColors from '../product-colors/ProductColors';
import request from '../request';
import SaleSvg from '../svgs/saleSvg';

class ProductItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hoveringFavouriteButton: false,
      showToast: false,
      showFavouriteToast: false,
      showErrorToast: false,
      thumbnail: '',
      count: 1,
      isFavourite: favouritesHelper.favourites.findIndex((i) => i.id === this.props.data.id) > -1,
    };

    this.onButtonFavouriteMouseOver = this.onButtonFavouriteMouseOver.bind(this);
    this.onButtonFavouriteMouseLeave = this.onButtonFavouriteMouseLeave.bind(this);
    this.onButtonFavouriteClick = this.onButtonFavouriteClick.bind(this);
    this.closeToast = this.closeToast.bind(this);
    this.onCountChange = this.onCountChange.bind(this);
    this.closeFavouriteToast = this.closeFavouriteToast.bind(this);
    this.closeErrorToast = this.closeErrorToast.bind(this);
    this.onChangeImage = this.onChangeImage.bind(this);
  }

  componentDidMount() {
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
    favouritesHelper.onChange(() => {
      this.setState({
        ...this.state,
        isFavourite: favouritesHelper.favourites.findIndex((i) => i.id === this.props.data.id) > -1,
      });
    });

    const image = new Image();
    image.onload = () => {
      this.setState({
        ...this.state,
        thumbnail: this.props.data.image,
      });
    };

    image.onerror = () => {
      this.setState({
        ...this.state,
        thumbnail: '/no-product-image-image.png',
      });
    };

    image.src = this.props.data.image;
  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange()
  }

  onButtonFavouriteMouseOver() {
    this.setState({
      ...this.state,
      hoveringFavouriteButton: true,
    });
  }

  onButtonFavouriteMouseLeave() {
    this.setState({
      ...this.state,
      hoveringFavouriteButton: false,
    });
  }

  onButtonFavouriteClick() {
    const addedAsFavourite = favouritesHelper.add(this.props.data);

    this.setState({
      ...this.state,
      showFavouriteToast: addedAsFavourite,
      isFavourite: favouritesHelper.favourites.findIndex((i) => i.id === this.props.data.id) > -1,
    });

    setTimeout(() => {
      this.setState({
        ...this.state,
        showFavouriteToast: false,
      });

      this.forceUpdate();
    }, 5000);
  }

  

  closeToast() {
    this.setState({
      ...this.state,
      showToast: false,
    });
  }

  onCountChange(value) {
    this.setState({
      ...this.state,
      count: value,
    });
  }

  closeFavouriteToast() {
    this.setState({
      ...this.state,
      showFavouriteToast: false,
    });
  }
  
  closeErrorToast() {
    this.setState({
      ...this.state,
      showErrorToast: false,
    });
  }

  setNewVariantData(v) {
    this.setState({
      ...this.state,
      selectedVariant: v,
    });
  }

  onChangeImage(p) {
    if(p.url){
      this.setState({
        ...this.state,
        thumbnail: p.url,
      });
    }else{
      request.get(`api/product/color_image/${this.props.data.id}/${p.value}`).then( (response) => {
        if(response.status === 200){
          this.setState({
            ...this.state,
            thumbnail: response.data.image,
          });
        }else{
          // console.log("Image not found");
        }
      })
    }
    
  }

  handleOnMouseDown (e) {
    this.setState({
      clientXonMouseDown: e.clientX,
      clientYonMouseDown: e.clientY
    })
    e.preventDefault() // stops weird link dragging effect
  }

  handleOnClick (e) {
    e.stopPropagation()
    if (this.state.clientXonMouseDown !== e.clientX || 
        this.state.clientYonMouseDown !== e.clientY) {
      // prevent link click if the element was dragged
      e.preventDefault()
    }
  }

  getBrandImage(id) {
    if(id == "4"){
      return 'K PRIME.png';
    }else if(id == "1"){
      return 'FAGEO.png';
    }else if(id == "3"){
      return 'KEYA.png';
    }else if(id == "5"){
      return 'GILDAN.png';
    }else if (id == "6"){
      return 'FOTL.png';
    }
    return false; 
  }

  render() {
    return (
      <div className="product-item"> 
        {
          this.getBrandImage(this.props.data.manufacturer) &&
          <div className='brand-mini-img'>
            <img src={`https://manager.cotton-plus.eu/images/${this.getBrandImage(this.props.data.manufacturer)}`} alt="brand image"/>
          </div>
        }

        
        <div
          className="image-wrapper"
          
        >
         
          <Link
            to={`/products/${this.props.data.id}`}
            aria-label={i18n.get_name(this.props.data)}
            onMouseDown={e => this.handleOnMouseDown(e)}
            onClick={e => this.handleOnClick(e)}
          >
            <img src={this.state.thumbnail} />
            {
              this.props.data.discount &&
              <div className='discount-banner'>
                <span>-{this.props.data.discount.percentage}%</span>
              </div>
            }
            {
              this.props.data.campain &&
              <div className='campain-banner'>
                <span>
                  <FontAwesomeIcon
                    icon={faGifts}
                  />
                </span>
              </div>
            }
          </Link>
          
        </div>
        
        <div
          className="btn-favourite"
          onMouseOver={this.onButtonFavouriteMouseOver}
          onMouseLeave={this.onButtonFavouriteMouseLeave}
          onClick={this.onButtonFavouriteClick}
        >
          {
            this.state.hoveringFavouriteButton || this.state.isFavourite
              ? <FontAwesomeIcon
                  icon={faHeartFilled}
                  size="lg"
                />
              : <FontAwesomeIcon
                  icon={faHeart}
                  size="lg"
                />
          }
        </div>
        
        <div className="info">
          <Link
            to={`/products/${this.props.data.id}`}
            className="name"
          >
            <b className='infoCode'>{this.props.data.code}</b> {i18n.get_name(this.props.data)}
          </Link>
          
          <div className="color-picker-wrapper">
                <ProductColors data={this.props.data.colors} onChangeImage={ (p) => {this.onChangeImage(p)}}/>
          </div>
        </div>
        

        

        

        <div className={`toast toast-error ${this.state.showErrorToast ? 'open' : ''}`}>
          <FontAwesomeIcon
            icon={faTimes}
            className="btn-close"
            onClick={this.closeErrorToast}
          />
          {i18n.t('product_added_error')}
        </div>

        <div className={`toast toast-favourite ${this.state.showFavouriteToast ? 'open' : ''}`}>
          <FontAwesomeIcon
            icon={faTimes}
            className="btn-close"
            onClick={this.closeFavouriteToast}
          />
          {i18n.t('product_added_as_favourite')}
        </div>
        {
          this.props.data.scale_discount ? 
          <SaleSvg className='discount-scale-product' color='#009688' size='56px'/> : ''
        }
      </div>
    );
  }
}

export default ProductItem;