import './SignupPage.css';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import request from '../request';
import history from '../history';
import Select from 'react-select';
import Meta from '../meta';

class SignupPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        name: '',
        lastname: '',
        email: '',
        password: '',
        passwordConfirm: '',
        company_name: '',
        tax_registration_number: '',
        doy: '',
        country: 'CYPRUS',
        region: '',
        zip: '',
        phone: '',
        activity_code: '',
        address: '',
        city: '',
        how_you_found_us: null,
        text_reason: ''
      },
      checked: false,
      showError: false,
      errorMessage: '',
      emailValid: null,
      passwordValid: null,
      countries: null,
      regions: null,
      doy: null,
      doyVisible: false,
      showMessage: false,
      successMessage: ''
    };

    this.signupSubmit = this.signupSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validateEmail2 = this.validateEmail2.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.getLookups = this.getLookups.bind(this);
    // this.validatePassword = this.validatePassword.bind(this);
  }

  componentDidMount() {
    this.getLookups()
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
    document.title = i18n.t("signup_page_title");
    
  }
  componentDidUpdate() {
    document.title = i18n.t("signup_page_title");
  }
  componentWillUnmount() {
    this.unlistenOnLanguageChange();
  }
  
  getLookups(){
    request.get(`api/signup_lookups`).then((response) => {          
      if (response.status) {
        this.setState({
          ...this.state,
          countries: response.data.data.countries,
          regions: response.data.data.regions,
          doy: response.data.data.doy,
        })
      }else{
        // console.log("invalid response")
      }
    });
  }
  onInputChange(e, inputName) {
    let emailStatus = this.state.emailValid;

    if(inputName === 'email') {
      emailStatus = this.validateEmail(e);
    }   
    let doyVisible = this.state.doyVisible;

    
    let doy = this.state.form.doy;
    if(inputName === 'doy') {
      doy = e.value
    }

    if(inputName === 'country') {
      if(e.target.value == 'ΕΛΛΑΣ'){
        doyVisible = true;
      }else{
        doyVisible = false;
        doy = '';
      }
    }
    let val;
    if(!e.target){
      val = e.value
    }else{
      val = e.target.value
    }
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [inputName]: val,
        doy: doy,
      },
      emailValid: emailStatus,  
      doyVisible: doyVisible   
    });
  }
  

  handleCheckbox(event) {   
    const value = event.target.checked;
    this.setState({
      ...this.state,
      checked: value
    })
  }


  validateEmail(e) {
    const value = e.target.value;    
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;     
    if( value.match(mailformat) ) {       
      return (true);  //valid
    }else{     
      return (false); //not valid
    }        
  }

  validateEmail2(e) {    
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;     
    if( e.match(mailformat) ) {       
      return (true);  //valid
    }else{     
      return (false); //not valid
    }        
  }
  
  validateForm() {
    const errors = {};
    
    errors.name = this.state.form.name == '' || this.state.form.name == null;
    errors.lastname = this.state.form.lastname == '' || this.state.form.lastname == null;
    errors.phone = this.state.form.phone == '' || this.state.form.phone == null;
    errors.address = this.state.form.address == '' || this.state.form.address == null;
    errors.city = this.state.form.city == '' || this.state.form.city == null;
    errors.region = (this.state.form.region == '' || this.state.form.region == null) && this.state.form.country == 'ΕΛΛΑΣ';
    errors.country = this.state.form.country == '' || this.state.form.country == null;
    errors.zip = this.state.form.zip == '' || this.state.form.zip == null;
    errors.company_name = this.state.form.company_name == '' || this.state.form.company_name == null;
    errors.tax_registration_number = this.state.form.tax_registration_number == '' || this.state.form.tax_registration_number == null;
    errors.how_you_found_us = this.state.form.how_you_found_us === null
    var valid = true;

    Object.entries(errors).map(item => {
      if(item[1]) valid = false;
    })

    return valid;
  }
  signupSubmit() {
    let name = this.state.form.name;
    let lastname = this.state.form.lastname;
    let email = this.state.form.email;
    let emailValid = this.validateEmail2(email);    
    let message = '';
    
    // let passwordValid = this.validatePassword();
    let passwordValid = false;
    let password = this.state.form.password ;
    let passwordConfirm = this.state.form.passwordConfirm ;
    let terms = this.state.checked;

    // validate password 
    if(  password !== '' && password && passwordConfirm !== '' && password === passwordConfirm  ) {
      passwordValid = true;
    }else {
      passwordValid = false;
    }        
    
    //let status = 'error';
    if(!this.validateForm()){
      message = i18n.t("fill_all_fields_prompt");
    }else if(emailValid === true && passwordValid === true  && terms === true && name !== '' && lastname !== ''  ) {
      request.post(`api/signup`, {
        ...this.state.form        
      }).then((response) => {          
        if (response.data.status) {
          // history.push('/');
          this.showMessage(true, '');
        } else {
          this.showMessage(response.data.status, response.data.message); 
        }
       
      });
    }else if (passwordValid === false && emailValid === false && terms === false && password === '' && passwordConfirm === '' && name === '' ){
      message = i18n.t("fill_all_fields_prompt");
    }else if( passwordValid === false){
      message = i18n.t("password_missmatch");
    }else if( emailValid === false) {
      message = i18n.t("invalid_email");
    }else if ( name === '' || lastname === '') {
      message = i18n.t("fill_all_fields_prompt");
    }else {
      message = i18n.t("accept_terms_prompt");
    }

    this.showMessage('error', message);
  }
  // End sign up



  showMessage(status, message){   
    if(status == true){
      this.setState({
        ...this.state,
        successMessage: message,
        showMessage: true,
      }) 
    }else{
      this.setState({
        ...this.state,
        errorMessage: message,
        showError: true,
      }) 
    }
      
  }

  onRadioChange = (event) => {
    this.setState({
      form: {
        ...this.state.form,
        how_you_found_us: event.target.value
      }
    });
  }

  render() {
    return (
      <div className="signup-page">
        <Meta 
          title={i18n.t("signup_page_title")}
          description={i18n.t("signup_page_title")}
          image="'https://cotton-plus.eu/img/logo/logo.png"
          url={window.location}
        />
        {
          !this.state.showMessage ?
        
        <div className="form">
          <div className="header">
            <h4>{i18n.t('signup_on_header')}</h4>
            <span style={{fontSize: '12px'}}>{i18n.t('signup_on_directions')}</span>
          </div>
          <h4>{i18n.t('signup_personal')}</h4>
          <div className="fields">
            
            <div className="input">
              <span>{i18n.t('name')}</span>
              <input
                type="text"
                value={this.state.form.name}
                onChange={(e) => this.onInputChange(e, 'name')}
              ></input>
            </div>
            <div className="input w-50">
              <span>{i18n.t('lastname')}</span>
              <input
                type="text"
                value={this.state.form.lastname}
                onChange={(e) => this.onInputChange(e, 'lastname')}
              ></input>
            </div>

            <div className="input w-100">
              <span>{i18n.t('email')}</span>
              <input
                type="email"
                className={this.state.emailValid === false ? 'error' : ''}
                value={this.state.form.email}
                onChange={(e) => {this.onInputChange(e, 'email'); } }
              ></input>
            </div>

            <div className="input">
              <span>{i18n.t('password')}</span>
              <input
                type="text"
                className={this.state.passwordValid === true ? 'error' : ''}
                value={this.state.form.password}
                onChange={(e) => this.onInputChange(e, 'password')}
              ></input>
            </div>
            <div className="input">
              <span>Επιβεβαίωση Κωδικού</span>
              <input
                type="text"
                className={this.state.passwordValid === true ? 'error' : ''}
                value={this.state.form.passwordConfirm}
                onChange={(e) => this.onInputChange(e, 'passwordConfirm')}
              ></input>
            </div>
          </div>

          <h4>{i18n.t('signup_company')}</h4>
          <div className="fields">
            <div className="input">
              <span>{i18n.t('country')}</span>
              {
                this.state.countries && 
                <select
                  value={this.state.form.country}
                  onChange={(e) => {this.onInputChange(e, 'country'); } }
                >
                  {
                    this.state.countries.map( (d, i) => {
                      return <option key={i} value={d.id}>{d.name}</option>
                    })
                  }
                </select>
              }
            </div>
            <div className="input">
              <span>{i18n.t('company_name')}</span>
              <input
                type="text"
                value={this.state.form.company_name}
                onChange={(e) => this.onInputChange(e, 'company_name')}
              ></input>
            </div>
            <div className="input">
              <span>{i18n.t('activity_code')}</span>
              <input
                type="text"
                value={this.state.form.activity_code}
                onChange={(e) => this.onInputChange(e, 'activity_code')}
              ></input>
            </div>
            <div className="input">
              <span>{this.state.doyVisible ? i18n.t('tax_registration_number') : 'Tax registration number'}</span>
              <input
                type="text"
                value={this.state.form.tax_registration_number}
                onChange={(e) => this.onInputChange(e, 'tax_registration_number')}
              ></input>
            </div>
            {
              this.state.doyVisible &&
              <div className="input">
              <span>{i18n.t('doy')}</span>
              
                {
                  this.state.doy &&
                  <Select
                  id="choose_address"
                  type="text"
                  options={
                    this.state.doy.map(a => {
                      return {
                        label: `${a.description}`,
                        value: a.code
                      }
                    })
                  }
                  value={
                    this.state.form.doy ? this.state.doy.filter(a => a.code == this.state.form.doy).map(a => {
                      return {
                        label: `${a.description}`,
                        value: a.code
                      }
                    }) : 
                    ''
                  }
                  onChange={(e) => {this.onInputChange(e, 'doy'); } }
                  />
                  // <select
                  //   value={this.state.form.doy}
                  //   onChange={(e) => {this.onInputChange(e, 'doy'); } }
                  // >
                  //   {
                  //     this.state.doy.map( (d, i) => {
                  //       return <option key={i} value={d.code}>{d.description}</option>
                  //     })
                  //   }
                  // </select>
                }

              
            </div>
            }
            
            
          </div>

          <h4>{i18n.t('signup_address')}</h4>
          <div className="fields">
            
            <div className="input">
              <span>{i18n.t('address')}</span>
              <input
                type="text"
                value={this.state.form.address}
                onChange={(e) => this.onInputChange(e, 'address')}
              ></input>
            </div>
            <div className="input">
              <span>{i18n.t('city')}</span>
              <input
                type="text"
                value={this.state.form.city}
                onChange={(e) => this.onInputChange(e, 'city')}
              ></input>
            </div>
            {
              this.state.regions && this.state.form.country != 'CYPRUS' &&
                
            
              <div className="input">
                <span>{i18n.t('region')}</span>
                {
                  this.state.regions && this.state.form.country == 'ΕΛΛΑΣ' ? 
                  <select
                    value={this.state.form.region}
                    onChange={(e) => {this.onInputChange(e, 'region'); } }
                  >
                    {
                      this.state.regions.map( (d, i) => {
                        return <option key={i} value={d.id}>{d.description}</option>
                      })
                    }
                  </select>
                  : this.state.regions && this.state.form.country != 'CYPRUS' &&
                  <input
                  type="text"
                    value={this.state.form.region}
                    onChange={(e) => this.onInputChange(e, 'region')}
                  ></input>
                }
                
              </div>
            }
            <div className="input">
              <span>{i18n.t('zip')}</span>
              <input
                type="text"
                value={this.state.form.zip}
                onChange={(e) => this.onInputChange(e, 'zip')}
              ></input>
            </div>

            <div className="input">
              <span>{i18n.t('phone')}</span>
              <input
                type="text"
                value={this.state.form.phone}
                onChange={(e) => this.onInputChange(e, 'phone')}
              ></input>
            </div>
            
          </div>

          <h4>{i18n.t('how_you_found_us')}</h4>
          <div className="fields d-flex direction-column w-100">
            <div>
              <label>
                <input type="radio" onChange={this.onRadioChange} checked={parseInt(this.state.form.how_you_found_us) === 1} value="1" name="how_you_found_us_value" /> Social media
              </label>
            </div>
            <div>
              <label>
                <input type="radio" onChange={this.onRadioChange} checked={parseInt(this.state.form.how_you_found_us) === 2} value="2" name="how_you_found_us_value" /> {i18n.t('Αναζήτηση Google')}
              </label>
            </div>
            <div>
              <label>
                <input type="radio" onChange={this.onRadioChange} checked={parseInt(this.state.form.how_you_found_us) === 3} value="3" name="how_you_found_us_value" /> {i18n.t('Επίσκεψη πωλητή')}
              </label>
            </div>
            <div>
              <label>
                <input type="radio" onChange={this.onRadioChange} checked={parseInt(this.state.form.how_you_found_us) === 4} value="4" name="how_you_found_us_value" /> {i18n.t('Από κάποιον τρίτο')}
              </label>
            </div>
            <div>
              <label>
                <input type="radio" onChange={this.onRadioChange} checked={parseInt(this.state.form.how_you_found_us) === 5} value="5" name="how_you_found_us_value" /> {i18n.t('Άλλο')}
              </label>
            </div>
            <div className={`w-100 mt-2${parseInt(this.state.form.how_you_found_us) === 5 ? '' : ' d-none'}`}>
              <label>{i18n.t('describe_how_you_found_us')}</label>
              <textarea 
                value={this.state.form.text_reason}
                className='w-100'
                onChange={(e) => this.setState({form: {...this.state.form, text_reason: e.target.value}})}
              ></textarea>
            </div>
          </div>

          <div className="fs-md pl-2 pt-2">            
            <input
              type="checkbox"
              checked={this.state.checked}
              onChange={this.handleCheckbox}
            />
            <label>{i18n.t('register_accept_terms')}</label>

          </div>

          <div className="controls">
            <div className={`alert alert-danger ${this.state.showError === false ? 'hidden' : ''}`}>
              {this.state.errorMessage}
            </div>
          </div>

          <div className="controls">
          
            <button
              type="button"
              className="btn btn-lg btn-color"
              onClick={this.signupSubmit}
            >
              {i18n.t('create_account_button')}
            </button>
            <div className="already-have-an-account-note">
              <span>{i18n.t('have_an_account')}</span>
              &nbsp;
              <Link to="/signin">{i18n.t('login_here')}</Link>
            </div>
          </div>
        </div>
        :
        <div className='success-msg'>
          {i18n.t("you_registered_succesfully")}
        </div>
        }
      </div>
    );
  }
}

export default SignupPage;

