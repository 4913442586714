import './Discounts.css';
import React from 'react';
import request from '../request';
import storage from '../storage';
import basket from '../basket';
import discountBasket from '../discountBasket';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ProductDiscount from '../product-discount/ProductDiscount';
import i18n from '../i18n';




class Discounts extends React.Component {
  constructor(props) {
    super(props);
    let prods = JSON.parse(localStorage.getItem("basket"))
    this.state = {
      discounts: null,
      discount_products: null,
      products: prods
    }
  }

  componentDidMount() {
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });

    this.basketUnmount = basket.onChange(() => {
      setTimeout(() => {
        this.loadDiscounts()
      }, 1000)
    });

    this.discountBasketUnmount = discountBasket.onChange(() => {
      this.setState({
        ...this.state,
        discount_products: discountBasket.products
      })
    });
    
    //this.loadDiscounts()
  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange();
    this.basketUnmount();
    this.discountBasketUnmount();
  }

  loadDiscounts() {
    let prods = JSON.parse(localStorage.getItem("basket"));

    request.post(`api/get-discounts/`, {basket: prods} ).then((response) => {
      if(response && response.data && response.data.discounts){
        
        this.setState({
          ...this.state,
          //discounts: d,
          discount_products: discountBasket.products,
          products: prods
        }, () => {
          let d = this.calcTotalQty(response.data.discounts)
          this.setState({
            ...this.state,
            discounts: d,
          });
        })
      }
    })
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.open === false && this.props.open && this.state.discounts == null){
      this.loadDiscounts()
    }
  }

  closeDiscounts() {
    this.props.onClose(false)
  }

  countDiscount(discount) {
    if(this.state.discount_products){
      let count = 0
      let prods = this.state.discount_products.filter(p => p.discount.id == discount.id)
      if(prods.length > 0){
        count = prods.map(p => p.quantity).reduce((a, b) => a + b)
      }
      return count
    }

    return 0;
  }

  showDiscount(discount, show) {
    let discounts = this.state.discounts;

    discounts[discounts.findIndex(d => d.id === discount.id)].show = show

    this.setState({
      ...this.state,
      discounts: discounts
    })
  }

  calcTotalQty(d) {
    let ret = d.map(dis => {
      return {
        ...dis,
        total_qty: this.state.products.filter(p => parseInt(p.id) == parseInt(dis.product_id)).map(p => p.quantity).reduce((a, b) => a + b, 0)
      }
    })
    // console.log(ret)
    return ret
  }

  render(){
    return this.props.open ? 
                <div className="discounts-area-wrapper">
                  <div className={`discounts-area-container ${this.state.discounts && this.state.discounts.length > 0 ? '' : 'no-discounts'}`}>
                    <button className="close-discount-btn" onClick={() => this.closeDiscounts()}><FontAwesomeIcon icon={faTimes}/> <br/></button>
                    <div className='discounts-inner'>
                    {
                      this.state.discounts && this.state.discounts.length > 0 ?
                      <div className="discount-header">{i18n.t("you_have")} {this.state.discounts.length} {this.state.discounts.length > 1 ? i18n.t("active_discounts") : i18n.t("active_discounts")}</div>
                      : 
                      <div className="discount-header discount-header-empty">{i18n.t("no_active_discounts_prompt")}</div>
                    }
                    {
                      this.state.discounts && this.state.discounts.length > 0 &&
                      <div className="discounts">
                        {
                          this.state.discounts.map(d => {
                            return <div className="discount-line">
                                      <div className="product-item">
                                        <div
                                          className="image-wrapper"
                                          
                                        >
                                          <div
                                            aria-label={d.passive_product.title}
                                          >
                                            <img src={d.passive_product.thumbnail} />
                                          </div>
                                          
                                        </div>
                                        
                                        
                                        <div className="info">
                                          <span
                                            className="name"
                                          >
                                            <b className='infoCode'>{d.passive_product.code}</b> {d.passive_product.title}
                                          </span>
                                          <span className="discount-desc">
                                          {i18n.t("from_your_order_at_least")} {d.qty} {i18n.t("qty_for_product")} <b>#{d.active_product.code}</b> {i18n.t("you_earned")} {d.discount_type == 1 ? i18n.t("discount") : i18n.t("gift")} {i18n.t("for")} {i18n.t("same_qty_for_product")} <b>#{d.passive_product.code}</b> {i18n.t("for_specific_variants")}
                            
                                          </span>
                                          <div className='discount-actions'>
                                            <span>{i18n.t("setted")} {this.countDiscount(d)} / {d.total_qty} {i18n.t("qty_metric")}</span>
                                            <span className="discount-type">{d.discount_type == 1 ? `${i18n.t("discount")} ${d.percentage}% ${i18n.t("for")}` : i18n.t("gift")} {d.total_qty} {i18n.t("qty_metric")}</span>
                                            <button className='discount-action-edit' onClick={ () => this.showDiscount(d, true)}>{i18n.t("edit_discount")}</button>
                                          </div>
                                        </div>
                                      </div>
                                      <ProductDiscount discount={d} show={d.show ? d.show : false} closeDiscount={(e) => this.showDiscount(d, false)} />
                              </div>
                        }) 
            }
          </div>
        }
        </div>
      </div>
    </div>
    : ''
  } 
}

export default Discounts;